/* Ensure full viewport height and proper margins */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
}

.buyplan-container {
    min-height: 100vh; /* Full viewport height */
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Start content from the top */
    padding-top: 60px; /* Upper margin to avoid overlap with navbar */
    background-color: #f5f7fa; /* Lighter background for clean UI */
    width: 100vw; /* Use full viewport width */
    box-sizing: border-box; /* Include padding and borders in width */
    transition: background-color 0.3s ease-in-out; /* Smooth background transition */
}

.buyplan-container:hover {
    background-color: #eef2f7; /* Subtle background change on hover */
}

h2 {
    font-size: 28px;
    color: #333;
    text-align: center; /* Center the heading */
    margin-bottom: 20px; /* Space below heading */
    font-weight: bold;
    animation: fadeIn 1s ease-in-out; /* Fade-in effect */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px); /* Slide up effect */
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Error message */
.error-message {
    color: #d9534f;
    background-color: #f2dede;
    padding: 10px;
    border: 1px solid #ebccd1;
    border-radius: 5px;
    text-align: center;
    margin: 20px;
    animation: fadeIn 1s ease-in-out;
}

/* Full width for the table */
.quotations-table {
    width: 100%; /* Use full width of the viewport */
    border-collapse: collapse;
    margin: 0; /* Remove margins to use full width */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
    animation: slideIn 1s ease-in-out; /* Slide-in effect for the table */
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(-50px); /* Slide from the left */
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.quotations-table th,
.quotations-table td {
    padding: 16px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
}

.quotations-table th {
    background-color: #007bff; /* Blue header */
    color: #fff;
    font-weight: bold;
}

.quotations-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.quotations-table tr:hover {
    background-color: #f1f1f1;
    transition: background-color 0.3s ease;
}

.quotation-row td {
    padding: 12px;
    color: #555;
}

/* Download PDF button */
button {
    padding: 10px 20px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 25px; /* Rounded button */
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15); /* Subtle shadow */
    transition: all 0.3s ease; /* Smooth hover effects */
}

button:hover {
    background-color: #218838;
    transform: scale(1.05); /* Slight enlarge on hover */
}

button:focus {
    outline: none;
}

/* Ensure no margins for the table */
.quotations-table {
    margin: 0; /* Use full width without margins */
    width: 100vw; /* Full width */
}
/* Reset default styles */
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Arial', sans-serif;
    background: linear-gradient(135deg, #3c67c1, #99c2e2);
  }
  
  /* Dashboard container */
  .dashboard-container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Full viewport height */
  }
  
  /* Navbar styling */
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1560bd;
    padding: 0 20px; /* Horizontal padding */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: fixed; /* Fixed position */
    top: 0;
    left: 0;
    width: 100%;
    z-index: 50; /* Ensure it stays on top */
    height: 60px; /* Fixed height */
  }
  
  /* Main content area */
  .buyplan-container {
    flex-grow: 1; /* Allow it to fill available space */
    padding-top: 60px; /* Offset by navbar height */
    overflow-y: auto; /* Allow scrolling if needed */
  }
  
  /* Error message styling */
  .error-message {
    color: red;
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  
  /* Table styling */
  .quotations-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px; /* Space above table */
  }
  
  .quotations-table th,
  .quotations-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .quotation-row:hover {
    background-color: #f1f1f1;
  }
  
  /* Button styling */
  button {
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  .quotations-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .quotations-table th,
  .quotations-table td {
    padding: 12px;
    text-align: left; /* Default alignment */
    border: 1px solid #ccc;
  }
  
  .search-bar {
    padding: 8px;
    width: 20%;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    margin-left: 10px;
  }
  