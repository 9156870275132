body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Arial', sans-serif;
  background: linear-gradient(135deg, #3c67c1, #99c2e2);
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1560bd;
  padding: 0 20px; /* Remove vertical padding */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  height: 60px;
}

.left-section {
  display: flex;
  align-items: center;
}

.hamburger-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  width: 30px;
  height: 20px;
  margin-right: 15px;
}

.bar {
  height: 3px;
  width: 100%;
  background-color: white;
  margin: 3px 0;
}

.navbar-logo {
  width: 50px;
  height: auto;
  /* border-radius: 50%; Rounded logo for a modern look */
}

h1 {
  flex-grow: 1;
  text-align: center;
  font-size: 30px;
  margin: 0;
  color: white;
}

.login-button,.logout-button {
  background: #00308F;
  padding: 8px 15px;
  border: none;
  color: white;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  margin-right: 30px;
}

.login-button:hover,.logout-button:hover {
  background-color: #002D62;
}

/* Menu Content */
.menu-content {
  position: fixed;
  top: 60px;
  left: 0;
  width: 220px;
  background-color: #4A90E2;
  padding: 20px;
  box-shadow: 4px 0 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.menu-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-content ul li {
  padding: 10px 0;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: black;
  transition: color 0.3s ease;
}

.menu-content ul li:hover {
  color: white;
}

.menu-link {
  text-decoration: none;
  color: black;
}

.menu-link:hover {
  color: white;
}

/* Main content without space below the navbar */
.main-content {
  flex-grow: 1;
  padding-top: 60px; /* Adjust to navbar height */
}

.main-content-banner {
  width: 100%;
  height: auto;
  margin: 0; /* Remove extra margins */
  padding: 0; /* Remove padding */
}

.navbar-logo {
  margin-top: 10px;
  width: 61px;
  height: 40px;
  /* border-radius: 50%; Rounded logo for a modern look */
}